import * as React from 'react'
import FeaturedReview from 'components/featured-review'
import { ICommonCopyDict } from 'interfaces/common-copy'
import { IFeaturedReview } from 'interfaces/featured-review'
import styles from './style.module.css'
import { IRichText } from 'interfaces/rich-text'
import ContentfulRichText from 'components/rich-text'
import { BLOCKS } from '@contentful/rich-text-types'
import { IImage } from 'interfaces/media'
import { IUiResources } from 'interfaces/ui-resource'
import globalManifest from 'data/global-manifest.json'
import HorizontalScrollWithSlider from 'components/horizontal-scroll-with-slider'
import twConfig from 'tailwind-light-config'
const { screens } = twConfig

interface IProps {
    header?: IRichText
    reviews: IFeaturedReview[]
    commonCopy?: ICommonCopyDict
    bottomIllustration?: IImage
    uiResources: IUiResources
    subHeader?: IRichText
}
const lgBreakPoint = screens.lg

const { resources: globalUIResources } = globalManifest ?? {}

const headerRenderNode = {
    // eslint-disable-next-line react/display-name
    [BLOCKS.PARAGRAPH]: (_node, children) => <p className="section--header mt-0">{children}</p>,
}

const subHeaderRenderNode = {
    // eslint-disable-next-line react/display-name
    [BLOCKS.PARAGRAPH]: (_node, children) => (
        <p className="section--subheader text-left leading-140% mt-0">{children}</p>
    ),
}

const cardWidth = '280px'
const cardWidthLg = '380px'

export const FeaturedReviewsSection = ({
    header,
    reviews,
    commonCopy,
    bottomIllustration,
    uiResources,
    subHeader,
}: IProps): React.ReactElement => {
    if (!reviews?.length) {
        return null
    }

    return (
        <section className="section pb-0 bg-white">
            {(header || subHeader) && (
                <div>
                    {header && <ContentfulRichText content={header.json} renderNode={headerRenderNode} />}
                    {subHeader && (
                        <div className="mt-3.5 lg:mt-7.5 text-center">
                            <ContentfulRichText content={subHeader.json} renderNode={subHeaderRenderNode} />
                        </div>
                    )}
                </div>
            )}
            <div
                className={`relative ${styles['reviews-container']}`}
                style={{
                    ['--cardWidth' as string]: cardWidth,
                    ['--cardWidthLg' as string]: cardWidthLg,
                }}
            >
                <HorizontalScrollWithSlider
                    className={`pt-10 lg:pt-15 ${bottomIllustration ? '' : 'pb-10 lg:pb-15'} ${styles['reviews-list']}`}
                    rangeClassName={`w-full mt-6 ${styles['range-input']}`}
                    rangeLabel={globalUIResources?.['carouselSliderControlLabel']?.value as string}
                >
                    {reviews.map((review) => (
                        <FeaturedReview
                            uiResources={uiResources}
                            commonCopy={commonCopy}
                            key={review?.sys?.id}
                            featuredReview={review}
                        />
                    ))}
                </HorizontalScrollWithSlider>
            </div>
            {/* illustration */}
            {bottomIllustration && (
                <picture>
                    {/* webp */}
                    <source
                        type="image/webp"
                        srcSet={`
                            ${bottomIllustration.url}?fm=webp&w=200 200w,
                            ${bottomIllustration.url}?fm=webp&w=300 300w,
                            ${bottomIllustration.url}?fm=webp&w=400 400w,
                        `}
                        // roughly 230px and 100px
                        sizes={`(min-width: ${lgBreakPoint}) 230px, 100px`}
                    />
                    {/* jpeg */}
                    <source
                        type="image/jpeg"
                        srcSet={`
                            ${bottomIllustration.url}?fm=jpg&w=200 200w,
                            ${bottomIllustration.url}?fm=jpg&w=300 300w,
                            ${bottomIllustration.url}?fm=jpg&w=400 400w
                        `}
                        sizes={`(min-width: ${lgBreakPoint}) 230px, 100px`}
                    />
                    <img
                        src={bottomIllustration.url + '?fm=jpg&w=400'}
                        alt={bottomIllustration.description || bottomIllustration.title}
                        height={bottomIllustration.height}
                        width={bottomIllustration.width}
                        className="mt-12 lg:mt-16 w-40 lg:w-56 ml-auto lg:mr-24"
                        loading="lazy"
                    />
                </picture>
            )}
        </section>
    )
}
