/* eslint-disable react/display-name */
import * as React from 'react'
import ContentfulRichText from 'components/rich-text'
import { BLOCKS } from '@contentful/rich-text-types'
import useMobileScreenDetection from 'hooks/useMobileScreenDetection'
import { IUiResource, IUiResources } from 'interfaces/ui-resource'
import { IGenericPageSection } from 'interfaces/generic-page-section'
import VideoCard from 'components/video-card'

interface IProps {
    lowestStarterSetPrices?: IUiResource
    section: IGenericPageSection
    uiResources: IUiResources
}

interface StickerProps {
    url: string
    description: string
    title: string
    className: string
}

const headerRenderNode = {
    [BLOCKS.PARAGRAPH]: (_node, children) => (
        <h2 className="text-3.5xl lg:text-6.25xl text-black leading-110% tracking-tight font-medium">{children}</h2>
    ),
}

const subHeaderRenderNode = {
    [BLOCKS.PARAGRAPH]: (_node, children) => <p className="leading-140%">{children}</p>,
}

const Sticker = ({ url, description, title, className = '' }: StickerProps): React.ReactElement => {
    return (
        <picture className={className}>
            <source type="image/webp" srcSet={url + '?fm=webp&w=420'} />
            <source type="image/jpg" srcSet={url + '?fm=jpg&w=420'} />
            <img
                src={url + '?fm=jpg&w=420'}
                alt={description || title}
                loading="lazy"
                className="h-24 w-auto sm:h-full sm:w-full"
            />
        </picture>
    )
}

export const GenericHeroTextLeftVideoRightSection = ({ section, uiResources }: IProps): React.ReactElement => {
    const { header, mobileMediaCollection, mediaCollection, richSubHeader } = section
    const isMobileScreen = useMobileScreenDetection()

    if (isMobileScreen) {
        return (
            <>
                <section
                    className={`section--v2--sm grid grid-cols-1 lg:grid-cols-2 grid-rows-1 relative px-6 sm:px-20 sm:mt-24`}
                >
                    <div className="p-5 sm:pt-[5.5rem] lg:p-15 lg:pt-28 flex flex-col items-start relative z-10">
                        {header?.json && (
                            <h2>
                                <ContentfulRichText content={header.json} renderNode={headerRenderNode} />
                            </h2>
                        )}
                    </div>

                    <div className="relative mt-4">
                        <VideoCard
                            video={mediaCollection?.items?.[0]}
                            uiResources={uiResources}
                            className="rounded-2lg overflow-hidden"
                            playButtonIconClassName="scale-[2]"
                            pauseButtonIconClassName="scale-[2]"
                            iconButtonClassName="cursor-pointer"
                        />

                        <Sticker
                            url={mediaCollection?.items?.[1]?.url || mobileMediaCollection?.items?.[1]?.url || ''}
                            description={
                                mediaCollection?.items?.[1]?.description ||
                                mobileMediaCollection?.items?.[1]?.description ||
                                ''
                            }
                            title={mediaCollection?.items?.[1]?.title || mobileMediaCollection?.items?.[1]?.title || ''}
                            className="absolute sm:-bottom-20 sm:-left-10 -bottom-15 -left-5 z-1"
                        />

                        <Sticker
                            url={mediaCollection?.items?.[2]?.url || mobileMediaCollection?.items?.[2]?.url || ''}
                            description={
                                mediaCollection?.items?.[2]?.description ||
                                mobileMediaCollection?.items?.[2]?.description ||
                                ''
                            }
                            title={mediaCollection?.items?.[2]?.title || mobileMediaCollection?.items?.[2]?.title || ''}
                            className={`absolute z-2 ${isMobileScreen ? '-top-10 -right-5' : 'bottom-5 left-28'}`}
                        />

                        <Sticker
                            url={mediaCollection?.items?.[3]?.url || mobileMediaCollection?.items?.[3]?.url || ''}
                            description={
                                mediaCollection?.items?.[3]?.description ||
                                mobileMediaCollection?.items?.[3]?.description ||
                                ''
                            }
                            title={mediaCollection?.items?.[3]?.title || mobileMediaCollection?.items?.[3]?.title || ''}
                            className="absolute sm:-bottom-10 -bottom-15 left-28 sm:left-44 z-3"
                        />
                    </div>
                    <div className="p-5 pt-[5.5rem] lg:p-15 lg:pt-28 flex flex-col items-start relative z-10">
                        {richSubHeader?.json && (
                            <div className="block">
                                <ContentfulRichText content={richSubHeader.json} renderNode={subHeaderRenderNode} />
                            </div>
                        )}
                    </div>
                </section>
            </>
        )
    }

    return (
        <>
            <section
                className={`section--v2 grid grid-cols-1 lg:grid-cols-2 grid-rows-1 relative px-6 sm:px-20 mb-40 mt-12 sm:mt-24`}
            >
                {/* content */}
                <div className="p-5 pt-[5.5rem] lg:p-15 lg:pt-28 flex flex-col items-start relative z-10">
                    {header?.json && (
                        <h2>
                            <ContentfulRichText content={header.json} renderNode={headerRenderNode} />
                        </h2>
                    )}
                    {richSubHeader?.json && (
                        <div className="block">
                            <ContentfulRichText content={richSubHeader.json} renderNode={subHeaderRenderNode} />
                        </div>
                    )}
                </div>

                <div className="relative mt-4">
                    <VideoCard
                        video={mediaCollection?.items?.[0]}
                        uiResources={uiResources}
                        className="rounded-2lg overflow-hidden"
                        iconButtonClassName="cursor-pointer lg:h-20 lg:w-20"
                    />

                    <>
                        <Sticker
                            url={mediaCollection?.items?.[1]?.url || mobileMediaCollection?.items?.[1]?.url || ''}
                            description={
                                mediaCollection?.items?.[1]?.description ||
                                mobileMediaCollection?.items?.[1]?.description ||
                                ''
                            }
                            title={mediaCollection?.items?.[1]?.title || mobileMediaCollection?.items?.[1]?.title || ''}
                            className="absolute sm:-bottom-20 sm:-left-10 -bottom-15 -left-5 z-1"
                        />

                        <Sticker
                            url={mediaCollection?.items?.[2]?.url || mobileMediaCollection?.items?.[2]?.url || ''}
                            description={
                                mediaCollection?.items?.[2]?.description ||
                                mobileMediaCollection?.items?.[2]?.description ||
                                ''
                            }
                            title={mediaCollection?.items?.[2]?.title || mobileMediaCollection?.items?.[2]?.title || ''}
                            className={`absolute z-2 ${isMobileScreen ? '-top-10 -right-5' : 'bottom-5 left-28'}`}
                        />

                        <Sticker
                            url={mediaCollection?.items?.[3]?.url || mobileMediaCollection?.items?.[3]?.url || ''}
                            description={
                                mediaCollection?.items?.[3]?.description ||
                                mobileMediaCollection?.items?.[3]?.description ||
                                ''
                            }
                            title={mediaCollection?.items?.[3]?.title || mobileMediaCollection?.items?.[3]?.title || ''}
                            className="absolute sm:-bottom-10 -bottom-15 left-28 sm:left-44 z-3"
                        />
                    </>
                </div>
            </section>
        </>
    )
}
