import * as React from 'react'
import { IPageSectionIcons } from 'interfaces/page-section-icons'
import ArrowRightIcon from 'icons/arrow-right'
import { colors } from 'theme.cjs'
import { easeInOutQuad } from 'lib/util/misc'
import useIntersectionObserver from 'hooks/useIntersectionObserver'

interface IProps {
    section: IPageSectionIcons
}

const autoplayInterval = 2000

export const IconsWithQuotesSection = ({ section }: IProps): React.ReactElement => {
    const [scrollableContailerEl, setScrollableContainerEl] = React.useState<HTMLDivElement>()
    const [activeIconIndex, setActiveIconIndex] = React.useState(0)
    const [hasUserInteracted, setHasUserInteracted] = React.useState(false) // has user pressed the arrow buttons

    const { entry, observer } = useIntersectionObserver({ element: scrollableContailerEl })

    const { backgroundColor, iconsCollection } = section

    const totalIcons = iconsCollection.items.length

    const scrollableContainerRef = React.useCallback((node) => {
        if (node !== null) {
            setScrollableContainerEl(node)
        }
    }, [])

    const focusPrev = React.useCallback(() => {
        if (!scrollableContailerEl) {
            return
        }

        const newActiveIndex = activeIconIndex - 1
        setActiveIconIndex(newActiveIndex)

        // center the icon
        const iconElement = scrollableContailerEl.children[newActiveIndex] as HTMLPictureElement
        const iconWidth = iconElement.clientWidth

        const centerPosition = scrollableContailerEl.clientWidth / 2 // the position we'd want the icon to be around
        const currentScrollPosition = scrollableContailerEl.scrollLeft
        const iconLeftPosition = iconElement.offsetLeft // icon's position relative to parent

        // iconLeftPosition - currentScrollPosition => brings the icon into view
        // further subtract centerPostion to move the icon to the center
        // further subtract by half of the icon's width to center the icon on
        // that center postion line
        const scrollBy = iconLeftPosition - currentScrollPosition - centerPosition + iconWidth / 2

        if (window.CSS.supports('scroll-behavior', 'smooth')) {
            scrollableContailerEl.scrollBy({ left: scrollBy, behavior: 'smooth' })
            return
        }

        const startTime = performance.now()
        const duration = 0.5
        const startPosition = currentScrollPosition
        // scrollBy will be negative
        const endPosition = startPosition + scrollBy < 0 ? 0 : startPosition + scrollBy

        requestAnimationFrame(function scroll() {
            const timeElapsed = performance.now() - startTime
            const newPosition = Math.floor(easeInOutQuad(timeElapsed / 1000, startPosition, scrollBy, duration))
            scrollableContailerEl.scrollLeft = newPosition

            if (scrollableContailerEl.scrollLeft > endPosition) {
                requestAnimationFrame(scroll)
            }
        })
    }, [activeIconIndex, scrollableContailerEl])

    const focusNext = React.useCallback(() => {
        if (!scrollableContailerEl) {
            return null
        }

        const newActiveIndex = activeIconIndex + 1

        setActiveIconIndex(newActiveIndex)

        // center the icon
        const iconElement = scrollableContailerEl.children[newActiveIndex] as HTMLPictureElement
        const iconWidth = iconElement.clientWidth

        const centerPosition = scrollableContailerEl.clientWidth / 2
        const currentScrollPosition = scrollableContailerEl.scrollLeft
        const iconLeftPosition = iconElement.offsetLeft

        // currentScrollPosition - iconLeftPosition => brings the icon into view
        // further subtract by half of the icon's width to center the icon on
        // that center postion line
        // further add centerPostion to move the icon to the center
        const scrollBy = (currentScrollPosition - iconLeftPosition - iconWidth / 2 + centerPosition) * -1

        if (window.CSS.supports('scroll-behavior', 'smooth')) {
            scrollableContailerEl.scrollBy({ left: scrollBy, behavior: 'smooth' })
            return
        }

        const scrollWidth = scrollableContailerEl.scrollWidth
        const scrollerClientWidth = scrollableContailerEl.clientWidth

        const startTime = performance.now()
        const duration = 0.5
        const startPosition = currentScrollPosition
        const endPosition =
            startPosition + scrollBy > scrollWidth - scrollerClientWidth
                ? scrollWidth - scrollerClientWidth
                : startPosition + scrollBy

        requestAnimationFrame(function scroll() {
            const timeElapsed = performance.now() - startTime
            const newPosition = Math.ceil(easeInOutQuad(timeElapsed / 1000, startPosition, scrollBy, duration))
            scrollableContailerEl.scrollLeft = newPosition

            if (scrollableContailerEl.scrollLeft < endPosition) {
                requestAnimationFrame(scroll)
            }
        })
    }, [activeIconIndex, scrollableContailerEl])

    React.useEffect(() => {
        if (!entry?.isIntersecting || hasUserInteracted) {
            return
        }

        // disconnect observer when reaching the end
        if (activeIconIndex + 1 >= totalIcons) {
            observer?.disconnect()
            return
        }

        const timer = setTimeout(() => {
            focusNext()
        }, autoplayInterval)

        return () => {
            clearTimeout(timer)
        }
    }, [focusNext, hasUserInteracted, entry?.isIntersecting, activeIconIndex, observer, totalIcons])

    React.useEffect(() => {
        // autoplay is stopped after user interaction
        // disconnect observer
        if (hasUserInteracted) {
            observer?.disconnect()
        }
    }, [hasUserInteracted, observer])

    if (!section) {
        return null
    }

    const handleLeftButtonClick = () => {
        setHasUserInteracted(true)
        focusPrev()
    }

    const handleRightButtonClick = () => {
        setHasUserInteracted(true)
        focusNext()
    }

    const icons = iconsCollection.items

    return (
        <section
            className={`section--v2 p-0 ${
                backgroundColor ? 'section--small-margin-top' : ''
            } lg:px-15 bg-[color:var(--bgColor)] lg:bg-transparent`}
            style={{
                ['--bgColor' as string]: backgroundColor,
            }}
        >
            <div
                className={`rounded-2lg py-[calc(var(--sectionBlockMargin)/2)] lg:pt-20 lg:pb-12 bg-[color:var(--bgColor)] flex flex-col items-center`}
            >
                {/* quote */}
                <div className="grid grid-cols-1 justify-items-center">
                    {icons.map((icon, index) => {
                        const isActive = index === activeIconIndex
                        return (
                            <div
                                className={`text-1xl lg:text-3.25xl font-medium leading-120% tracking-tight text-center px-5 sm:px-6 lg:px-0 row-start-1 row-end-2 col-start-1 col-end-2 blockquote transition-opacity ${
                                    isActive ? 'opacity-100' : 'opacity-0'
                                }`}
                                key={icon.key}
                            >
                                {icon.value}
                            </div>
                        )
                    })}
                </div>

                {/* icons */}
                <div
                    ref={scrollableContainerRef}
                    // important: relative poisition needed to scroll programmatically
                    className="mt-12.5 flex overflow-auto scrollbar-hidden relative w-auto mx-auto"
                >
                    {icons.map((icon, index) => {
                        const isActive = index === activeIconIndex
                        return (
                            icon.media?.url && (
                                <button
                                    key={icon.key}
                                    className="ml-15 first:ml-5 first:sm:ml-6 first:lg:ml-15 last:mr-5 last:sm:mr-6 last:lg:mr-15 flex-shrink-0 overflow-auto"
                                    onClick={() => setActiveIconIndex(index)}
                                >
                                    <picture>
                                        {icon.media.url.endsWith('.svg') && (
                                            <source type="image/svg+xml" srcSet={icon.media.url} />
                                        )}

                                        <source type="image/webp" srcSet={icon.media.url + '?fm=webp&w=320'} />
                                        <source type="image/jpeg" srcSet={icon.media.url + '?fm=jpg&w=320'} />

                                        <img
                                            src={icon.media.url + '?fm=jpg&w=320'}
                                            alt={icon.media.title}
                                            height={icon.media.height}
                                            width={icon.media.width}
                                            className={`h-10 w-full ${isActive ? 'opacity-full' : 'opacity-30'}`}
                                            loading="lazy"
                                        />
                                    </picture>
                                </button>
                            )
                        )
                    })}
                </div>

                {/* arrow buttons */}
                <div className="flex items-center mx-auto mt-15 lg:mt-11">
                    <button
                        onClick={handleLeftButtonClick}
                        className="left-arrow h-9 w-9 flex justify-center items-center border rounded-full disabled:opacity-30"
                        disabled={activeIconIndex < 1}
                    >
                        <ArrowRightIcon className="rotate-180" strokeWidth={2} stroke={colors.mineshaft} />
                    </button>
                    <button
                        onClick={handleRightButtonClick}
                        className="right-arrow h-9 w-9 flex justify-center items-center border rounded-full ml-2 lg:ml-5 disabled:opacity-30"
                        disabled={activeIconIndex >= icons.length - 1}
                    >
                        <ArrowRightIcon strokeWidth={2} stroke={colors.mineshaft} />
                    </button>
                </div>
            </div>
        </section>
    )
}
