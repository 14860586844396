import * as React from 'react'
import { IPageSectionIcons } from 'interfaces/page-section-icons'
import styles from './style.module.css'
import useIntersectionObserver from 'hooks/useIntersectionObserver'
import { BLOCKS } from '@contentful/rich-text-types'
import ContentfulRichText from 'components/rich-text'
import { iconSectionOurPartnersInternalName } from 'constants/contentful'

interface IProps {
    section: IPageSectionIcons
}

const intersectionObserverOptions: IntersectionObserverInit = {
    threshold: 0.9,
}

const headerRenderNode = {
    // eslint-disable-next-line react/display-name
    [BLOCKS.PARAGRAPH]: (_node, children) => (
        <p className="text-center text-5xl font-medium tracking-tight section--header mb-12 sm:mb-16">{children}</p>
    ),
}

export const IconsSection = ({ section }: IProps): React.ReactElement => {
    const [scrollableContainerEl, setScrollableContainerEl] = React.useState<HTMLElement>()
    const [sectionEl, setSectionEl] = React.useState<HTMLElement>()

    const { header, internalName } = section

    const sectionElRef = React.useCallback((node) => {
        if (node !== null) {
            setSectionEl(node)
        }
    }, [])

    const scrollableContainerRef = React.useCallback((node) => {
        if (node !== null) {
            setScrollableContainerEl(node)
        }
    }, [])

    const { entry, observer } = useIntersectionObserver({
        element: scrollableContainerEl,
        options: intersectionObserverOptions,
    })

    React.useEffect(() => {
        let requestId

        let lastScrollPosition = 0

        if (entry?.isIntersecting && scrollableContainerEl) {
            const scrollLeft = () => {
                const currentScrollPosition = scrollableContainerEl.scrollLeft

                const hasScrolledToEnd =
                    scrollableContainerEl.scrollWidth <= scrollableContainerEl.clientWidth + currentScrollPosition

                // if the scroll position has updated from what was set
                // from this function then user has initiated scroll
                const didUserScroll = currentScrollPosition !== lastScrollPosition

                // disconnect observer + exit from requestAnimationFrame
                // when the element has scrolled to end or when user has
                // scrolled
                if (hasScrolledToEnd || didUserScroll) {
                    observer?.disconnect()
                    return
                }

                const newScrollPosition = currentScrollPosition + 1

                scrollableContainerEl.scrollLeft = newScrollPosition
                lastScrollPosition = newScrollPosition

                requestId = requestAnimationFrame(scrollLeft)
            }

            requestId = requestAnimationFrame(scrollLeft)
        }

        return () => {
            cancelAnimationFrame(requestId)
        }
    }, [entry?.isIntersecting, scrollableContainerEl, observer])

    const { backgroundColor, textColor, backgroundImage, iconsCollection, scrollable, renderType } = section ?? {}

    React.useEffect(() => {
        if (scrollable && sectionEl) {
            document.documentElement.style.setProperty('--iconsBannerHeight', sectionEl.clientHeight + 'px')
        }
    }, [scrollable, sectionEl])

    if (!iconsCollection?.items.length) {
        return null
    }

    const isSingleIcon = renderType === 'Single icon'
    const isIconsWithText = renderType === 'Icons with text'
    const isIconsWithHeaderAndText = renderType === 'Icons with header and text'
    const isIconsWithTextOnTheSide = renderType === 'Icons with text on the side'

    const isPartnersSection = isIconsWithText && internalName === iconSectionOurPartnersInternalName

    return (
        <section
            ref={sectionElRef}
            style={{
                backgroundColor,
                color: textColor,
            }}
            className={`section relative
                ${isSingleIcon ? 'py-20' : ''}
                ${isIconsWithText ? 'py-10 lg:px-[120px]' : ''}
                ${isPartnersSection ? 'lg:px-[120px]' : ''}
                ${isIconsWithHeaderAndText ? 'py-20 lg:px-[120px] lg:py-12.5' : ''}
                ${isIconsWithTextOnTheSide ? `py-8 ${backgroundColor ? 'section--small-margin-top' : ''}` : ''}
            `}
        >
            {header?.json && <ContentfulRichText content={header.json} renderNode={headerRenderNode} />}
            <div
                ref={scrollable ? scrollableContainerRef : null}
                className={`
                    ${
                        isIconsWithHeaderAndText
                            ? 'flex flex-col md:flex-row lg:justify-around px-6 flex-wrap -ml-5 -mt-7.5'
                            : scrollable
                            ? `-mx-5 lg:-mx-15 px-5 lg:px-15 ${styles['icons-scroll']}`
                            : styles['icons-grid']
                    } 
                    relative z-10
                `}
            >
                {iconsCollection.items.map(
                    (icon) =>
                        icon && (
                            <div
                                className={`
                                    flex items-center 
                                    ${!isIconsWithHeaderAndText && !isIconsWithTextOnTheSide ? 'flex-col' : ''}
                                    ${isIconsWithHeaderAndText ? 'mt-7.5 ml-5' : ''}
                                    ${isIconsWithTextOnTheSide ? 'items-center' : ''}
                                `}
                                key={icon.key}
                            >
                                <picture>
                                    {/* svg */}
                                    {icon.media?.url?.endsWith('.svg') && (
                                        <source type="image/svg+xml" srcSet={icon.media?.url} />
                                    )}
                                    {/* webp */}
                                    <source type="image/webp" srcSet={icon.media?.url + '?w=600&fm=webp'} />
                                    {/* jpeg */}
                                    <source type="image/jpeg" srcSet={icon.media?.url + '?w=600&fm=jpg'} />

                                    <img
                                        alt={(icon.value as string) || icon.media?.description || icon.media?.title}
                                        src={icon.media?.url + '?w=600&fm=jpg'}
                                        height={icon.media?.height}
                                        width={icon.media?.width}
                                        loading="lazy"
                                        className={`
                                            w-auto
                                            ${isSingleIcon ? 'h-40' : ''}
                                            ${isIconsWithText ? 'h-[2.125rem] lg:h-[3.625rem]' : ''}
                                            ${isIconsWithHeaderAndText ? 'h-14 lg:h-12.5' : ''}
                                            ${isIconsWithTextOnTheSide ? 'h-9' : ''}
                                        `}
                                    />
                                </picture>

                                {(isIconsWithText || isIconsWithHeaderAndText || isIconsWithTextOnTheSide) &&
                                    (icon.value as string)?.trim() && (
                                        <>
                                            <div
                                                className={`
                                                    leading-140% 
                                                    ${
                                                        !isIconsWithTextOnTheSide && !isIconsWithTextOnTheSide
                                                            ? 'text-center mt-2.5 lg:mt-4'
                                                            : ''
                                                    }
                                                    ${isIconsWithHeaderAndText ? 'text-xsm ml-6 flex flex-col' : ''}
                                                    ${isIconsWithTextOnTheSide ? 'ml-5' : ''}
                                                `}
                                            >
                                                <span
                                                    className={`
                                                        ${isIconsWithHeaderAndText ? 'font-bold uppercase' : ''} 
                                                        ${
                                                            isIconsWithTextOnTheSide
                                                                ? 'font-medium text-xbase lg:text-xl'
                                                                : ''
                                                        }`}
                                                >
                                                    {icon.value}
                                                </span>
                                                {icon.media?.description && <span>{icon.media.description}</span>}
                                            </div>
                                        </>
                                    )}
                            </div>
                        ),
                )}
            </div>

            {/* background image */}
            {backgroundImage && (
                <picture>
                    {/* webp */}
                    <source
                        type="image/webp"
                        srcSet={`
                            ${backgroundImage?.url}?w=360&fm=webp 360w,
                            ${backgroundImage?.url}?w=720&fm=webp 720w,
                            ${backgroundImage?.url}?w=1024&fm=webp 1024w,
                            ${backgroundImage?.url}?w=1280&fm=webp 1280w,
                            ${backgroundImage?.url}?w=1600&fm=webp 1600w
                        `}
                    />
                    {/* jpeg */}
                    <source
                        type="image/jpeg"
                        srcSet={`
                            ${backgroundImage?.url}?w=360&fm=jpg 360w,
                            ${backgroundImage?.url}?w=720&fm=jpg 720w,
                            ${backgroundImage?.url}?w=1024&fm=jpg 1024w,
                            ${backgroundImage?.url}?w=1280&fm=jpg 1280w,
                            ${backgroundImage?.url}?w=1600&fm=jpg 1600w
                        `}
                    />

                    <img
                        aria-hidden
                        className="w-full h-full object-cover absolute left-0 top-0"
                        src={backgroundImage.url + '?w=1024&fm=jpg'}
                        height={backgroundImage?.height}
                        width={backgroundImage?.width}
                        loading="lazy"
                        alt=""
                    />
                </picture>
            )}
        </section>
    )
}
