import { RenderNode } from '@contentful/rich-text-react-renderer'
import { BLOCKS } from '@contentful/rich-text-types'
import FAQs from 'components/faqs/faq'
import ContentfulRichText from 'components/rich-text'
import { IPageSectionAccordion } from 'interfaces/page-section-accordion'
import { organizeFAQsByCategory } from 'lib/util/faq'
import Link from 'next/link'
import * as React from 'react'

interface IProps {
    section: IPageSectionAccordion
}

const headerRenderNode: RenderNode = {
    [BLOCKS.PARAGRAPH]: (_node, children) => <h2 className="section--header text-center">{children}</h2>,
}

export const PageSectionAccordion = ({ section }: IProps): React.ReactElement => {
    if (!section) {
        return null
    }

    const { itemsCollection, header, layout, backgroundColor, cta } = section
    const faqs = itemsCollection.items

    return (
        <section className="section--v2 flex flex-col items-center" style={{ backgroundColor }}>
            {header?.json && (
                <ContentfulRichText content={header.json} renderNode={headerRenderNode} ignoreMarkdownStyles />
            )}
            {/* cta */}
            {cta?.href && (
                <Link
                    href={cta.href}
                    className="flex items-center justify-center mt-7 font-semibold underline underline-offset-2"
                >
                    {cta.name}
                </Link>
            )}
            {faqs.length > 0 && (
                <div className="mt-8 lg:mt-12 w-full">
                    <FAQs
                        singleColumnLayout={layout === 'Single column layout'}
                        faqs={organizeFAQsByCategory(faqs)}
                        categorize={false}
                    />
                </div>
            )}
        </section>
    )
}
