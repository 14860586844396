import * as React from 'react'
import Video from 'components/video/video'
import { IImage, IVideo } from 'interfaces/media'
import { IUiResources } from 'interfaces/ui-resource'
import globalManifest from 'data/global-manifest.json'
import { IconButton } from 'components/buttons/button'
import { colors } from 'theme.cjs'
import PauseIcon from 'icons/pause-icon'
import PlayIcon from 'icons/play-icon'

interface IProps {
    video: IVideo
    poster?: IImage
    uiResources: IUiResources
    className?: string
    iconButtonClassName?: string
    playButtonIconClassName?: string
    pauseButtonIconClassName?: string
}

const { resources: globalUIResources } = globalManifest

export const VideoCard = ({
    video,
    poster,
    uiResources,
    className = '',
    iconButtonClassName = '',
    playButtonIconClassName = '',
    pauseButtonIconClassName = '',
}: IProps): React.ReactElement => {
    const [isPlaying, setIsPlaying] = React.useState(false)
    const [videoPlayerEl, setVideoPlayerEl] = React.useState<HTMLVideoElement>()
    const [hasVideoStarted, setHasVideoStarted] = React.useState(false)

    const videoPlayerRef = React.useCallback((node) => {
        if (node !== null) {
            setVideoPlayerEl(node)
        }
    }, [])

    React.useEffect(() => {
        if (!videoPlayerEl) {
            return
        }

        const playEventListener = () => {
            setIsPlaying(true)
            setHasVideoStarted(true)
        }

        const pauseEventListener = () => {
            setIsPlaying(false)
        }

        videoPlayerEl.addEventListener('play', playEventListener)
        videoPlayerEl.addEventListener('pause', pauseEventListener)

        return () => {
            videoPlayerEl.removeEventListener('play', playEventListener)
            videoPlayerEl.removeEventListener('pause', pauseEventListener)
        }
    }, [videoPlayerEl])

    const toggleVideoPlay = () => {
        if (videoPlayerEl) {
            if (isPlaying) {
                videoPlayerEl.pause()
            } else {
                videoPlayerEl.play()
            }
        }
    }

    if (!video?.url) {
        return null
    }

    return (
        <div className="h-full relative group overflow-hidden">
            <Video
                ref={videoPlayerRef}
                className={`w-full h-full object-cover ${className}`}
                height={video.height}
                width={video.width}
            >
                <source src={video.url} type={video.contentType} />
                <span>{globalUIResources?.['browserDoesNotSupportVideoMessage']?.value}</span>
            </Video>

            {/* poster */}
            {!hasVideoStarted && poster?.url && (
                <picture className="absolute left-0 top-0 w-full h-full">
                    <source type="image/webp" srcSet={poster.url + '?fm=webp&w=400'} />
                    <source type="image/jpeg" srcSet={poster.url + '?fm=jpg&w=400'} />

                    <img
                        src={poster.url + '?fm=jpg&w=400'}
                        aria-hidden={true}
                        height={poster.height}
                        width={poster.width}
                        className="w-full h-full object-cover"
                        loading="lazy"
                        alt=""
                    />
                </picture>
            )}

            {/* play/pause button */}
            <IconButton
                onClick={toggleVideoPlay}
                className={`
                    w-14 h-14 lg:w-28 lg:h-28
                    ${isPlaying ? 'opacity-0' : ''} group-hover:opacity-100 duration-200
                    rounded-full border-[3px] border-solid lg:border-[5px] border-white
                    flex justify-center items-center
                    absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-20
                    ${iconButtonClassName}
                `}
                label={
                    isPlaying
                        ? (uiResources['pauseMediaLabel'].value as string)
                        : (uiResources['playMediaLabel']?.value as string)
                }
            >
                {isPlaying ? (
                    <PauseIcon className={`lg:scale-150 ${pauseButtonIconClassName}`} fill={colors.white} />
                ) : (
                    <PlayIcon className={`lg:scale-150 ${playButtonIconClassName}`} fill={colors.white} />
                )}
            </IconButton>
        </div>
    )
}
