import * as React from 'react'
import Button from 'components/buttons/button'
import Link from 'next/link'
import { IMedicine } from 'interfaces/medicine'
import { getFormattedNumberWithCurrency } from 'lib/util/currency'
import { JAR_PILL_VARIANT_REGEXP, FIRST_NUMBER_IDENTIFIER_REGEXP } from 'lib/util/sku-querying'
import twConfig from 'tailwind-light-config'
const { screens } = twConfig
import { useOTCCartContext } from 'context/otc-cart-context'
import { useCartSidebarContext } from 'context/cart-sidebar-context'
import { CART_ITEM_TYPE } from 'enum/cart-item-type'
import { getExternalLinksOfProduct } from 'utils/external-links-helper'
import { useIsBlockOTCProducts } from 'hooks/useIsBlockOtcProducts'

interface IProps {
    medicine: IMedicine
    isFetchingLatestShopifyDetails: boolean
    fullDrugFactsLabel: string
    ctaLabel: string
    ctaDisabledLabel: string
}

const lgBreakPoint = screens.lg

export const PageSectionProductCategoryOTCCard = ({
    medicine,
    isFetchingLatestShopifyDetails,
    fullDrugFactsLabel,
    ctaDisabledLabel,
    ctaLabel,
}: IProps): React.ReactElement => {
    const {
        sys,
        abstractImage,
        cabinetDrugName,
        pillType,
        pillTypePlural,
        linkedFrom: { productPageMedicineCollection },
        shopifyProduct,
        jarWithMedicineImage,
        frontPouchImage,
    } = medicine

    const { openSidebar: openCart } = useCartSidebarContext()
    const { addLineItem } = useOTCCartContext()
    const isBlockOtcProducts = useIsBlockOTCProducts()

    const addToCart = () => {
        const jarPillVariant = shopifyProduct.variants.edges.find((edge) => JAR_PILL_VARIANT_REGEXP.test(edge.node.sku))
        const productPage = productPageMedicineCollection.items[0].linkedFrom.productPageCollection.items[0]

        const { cartImage, slug } = productPage

        addLineItem({
            type: CART_ITEM_TYPE.MEDICINE,
            id: shopifyProduct.id,
            variantId: jarPillVariant.node.id,
            productName: cabinetDrugName,
            quantity: 1,
            variants: shopifyProduct.variants.edges.map((variant) => ({
                ...variant.node,
                thumbnail:
                    jarPillVariant.node.id === variant.node.id ? cartImage || jarWithMedicineImage : frontPouchImage,
            })),
            thumbnail: cartImage,
            pillType,
            pillTypePlural,
            slug,
            sku: jarPillVariant.node.sku,
            available: jarPillVariant.node.availableForSale,
        })
    }

    const jarPillVariantOfMedicine = shopifyProduct.variants.edges.find((edge) =>
        JAR_PILL_VARIANT_REGEXP.test(edge.node.sku),
    ).node
    const priceOfJarPillVariant = jarPillVariantOfMedicine.price
    const priceOfJarPillVariantWithCurrency = getFormattedNumberWithCurrency(
        Number(priceOfJarPillVariant.amount),
        priceOfJarPillVariant.currencyCode,
    )

    const totalPillsInJarPillVariants = Number(FIRST_NUMBER_IDENTIFIER_REGEXP.exec(jarPillVariantOfMedicine.sku)[0])

    const medicineProductPage = productPageMedicineCollection.items[0].linkedFrom.productPageCollection.items[0]

    const { shortDescription, slug } = medicineProductPage ?? {}

    const externalLinks = getExternalLinksOfProduct(medicineProductPage)
    const showPriceOrAddToCart = !externalLinks.hasExternalLink && !isBlockOtcProducts

    return (
        <Link href={'/products/' + slug} key={sys.id} className="flex flex-col snap-center">
            {abstractImage?.url && (
                <picture>
                    <source
                        type="image/webp"
                        media={`(min-width: ${lgBreakPoint})`}
                        srcSet={abstractImage.url + '?fm=webp&w=1200'}
                    />
                    <source
                        type="image/jpeg"
                        media={`(min-width: ${lgBreakPoint})`}
                        srcSet={abstractImage.url + '?fm=jpg&w=1200'}
                    />

                    <source type="image/webp" srcSet={abstractImage.url + '?fm=webp&w=800'} />
                    <source type="image/jpeg" srcSet={abstractImage.url + '?fm=jpg&w=800'} />

                    <img
                        src={abstractImage.url + '?fm=jpg&w=1200'}
                        alt={abstractImage.description || abstractImage.title}
                        height={abstractImage.height}
                        width={abstractImage.width}
                        className="h-full w-full object-cover rounded-2lg"
                        loading="lazy"
                    />
                </picture>
            )}

            <div className="flex flex-col justify-between self-end flex-grow w-full">
                <div className="mt-5">
                    <div>
                        <div className="flex justify-between space-x-2 lg:text-lg font-bold leading-120%">
                            <span>{cabinetDrugName}</span>
                            {showPriceOrAddToCart &&
                                (isFetchingLatestShopifyDetails ? (
                                    <div className="animate-pulse h-5 w-9 lg:h-6 lg:w-12 bg-gray rounded" />
                                ) : (
                                    <span>{priceOfJarPillVariantWithCurrency}</span>
                                ))}
                        </div>
                    </div>
                    <p className="mt-2 text-sm lg:text-base leading-140$ font-medium">{shortDescription}</p>
                </div>
                <div className="capitalize flex justify-between lg:space-x-4 items-end mt-5">
                    {/* mb-px needed to remove vertical scroll in the card */}
                    <div className="text-xs leading-110% font-medium hidden lg:block mb-px">
                        <div>
                            {totalPillsInJarPillVariants}{' '}
                            {totalPillsInJarPillVariants === 1 ? pillType : pillTypePlural}
                        </div>
                        <button className="underline text-gray-darker mt-0.5 block">{fullDrugFactsLabel}</button>
                    </div>

                    {showPriceOrAddToCart && (
                        <Button
                            disabled={!jarPillVariantOfMedicine.availableForSale || isFetchingLatestShopifyDetails}
                            onClick={(e) => {
                                e.preventDefault()
                                addToCart()
                                openCart()
                            }}
                            className="px-10 disabled:opacity-50 min-w-[17ch]"
                            loading={isFetchingLatestShopifyDetails}
                        >
                            {jarPillVariantOfMedicine.availableForSale ? ctaLabel : ctaDisabledLabel}
                        </Button>
                    )}
                </div>
            </div>
        </Link>
    )
}
