import { BLOCKS } from '@contentful/rich-text-types'
import ContentfulRichText from 'components/rich-text'
import { IGenericPageSection } from 'interfaces/generic-page-section'
import * as React from 'react'
import twConfig from 'tailwind-light-config'
const { screens } = twConfig

interface IProps {
    section: IGenericPageSection
}

const lgBreakPoint = screens.lg

const renderNode = {
    // eslint-disable-next-line react/display-name
    [BLOCKS.PARAGRAPH]: (_node, children) => (
        <p className="text-white text-3xl lg:text-[2.75rem] leading-110% font-medium tracking-tight">{children}</p>
    ),
}

export const TextLeftRightOverMedia = ({ section }: IProps): React.ReactElement => {
    if (!section) {
        return null
    }

    const { mediaCollection, mobileMediaCollection, header, richSubHeader } = section

    const desktopImage = mediaCollection?.items?.[0]
    const mobileImage = mobileMediaCollection?.items?.[0]

    return (
        <section className="section section--bigger-padding p-0 grid grid-cols-1 grid-rows-1">
            {/* image */}
            {(desktopImage || mobileImage) && (
                <picture className="col-start-1 row-start-1">
                    {desktopImage?.url && (
                        <>
                            {/* webp */}
                            <source
                                type="image/webp"
                                media={`(min-width: ${lgBreakPoint})`}
                                srcSet={`
                                            ${desktopImage.url}?fm=webp&w=1024 1024w,
                                            ${desktopImage.url}?fm=webp&w=1280 1280w,
                                            ${desktopImage.url}?fm=webp&w=1600 1600w,
                                            ${desktopImage.url}?fm=webp&w=2000 2000w,
                                        `}
                            />

                            {/* jpeg */}
                            <source
                                type="image/jpeg"
                                media={`(min-width: ${lgBreakPoint})`}
                                srcSet={`
                                            ${desktopImage.url}?fm=jpg&w=1024 1024w,
                                            ${desktopImage.url}?fm=jpg&w=1280 1280w,
                                            ${desktopImage.url}?fm=jpg&w=1600 1600w,
                                            ${desktopImage.url}?fm=jpg&w=2000 2000w,
                                        `}
                            />
                        </>
                    )}

                    {mobileImage?.url && (
                        <>
                            {/* svg */}
                            {mobileImage.url.endsWith('.svg') && <source type="image/webp" srcSet={mobileImage.url} />}

                            {/* webp */}
                            <source
                                type="image/webp"
                                srcSet={`
                                            ${mobileImage.url}?fm=webp&w=420 420w,
                                            ${mobileImage.url}?fm=webp&w=640 640w,
                                            ${mobileImage.url}?fm=webp&w=820 820w
                                        `}
                            />

                            {/* jpeg */}
                            <source
                                type="image/jpeg"
                                srcSet={`
                                            ${mobileImage.url}?fm=jpg&w=420 420w,
                                            ${mobileImage.url}?fm=jpg&w=640 640w,
                                            ${mobileImage.url}?fm=jpg&w=820 820w
                                        `}
                            />
                        </>
                    )}

                    <img
                        src={(mobileImage?.url || desktopImage?.url) + '?fm=jpg&w=1024'}
                        alt={
                            mobileImage?.description ||
                            desktopImage?.description ||
                            mobileImage?.title ||
                            desktopImage?.title
                        }
                        height={mobileImage?.height || desktopImage?.height}
                        width={mobileImage?.width || desktopImage?.width}
                        className="h-full w-full max-h-[var(--screenHeightDeductingHeader)]"
                        loading="lazy"
                    />
                </picture>
            )}

            <div className="col-start-1 row-start-1 flex flex-col justify-between p-5 lg:p-15">
                {/* header */}
                {header?.json && (
                    <ContentfulRichText content={header.json} renderNode={renderNode} className="header" />
                )}

                {/* sub header */}
                {richSubHeader?.json && (
                    <ContentfulRichText
                        content={richSubHeader.json}
                        renderNode={renderNode}
                        className="sub-header ml-auto"
                    />
                )}
            </div>
        </section>
    )
}
